/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 13, 2018 */

/*---- All fonts
font-family:'Didot';
font-family:'Gotham Bold';
font-family:'Gotham Book';
font-family:'Gotham Light';
font-family:'Gotham Medium';
font-family:'Times';
*/

@font-face {
    font-family:'Gotham Bold';
    src: url('Gotham-Bold.eot');
	src: url('Gotham-Bold.eot?#iefix') format('embedded-opentype'),
		url('Gotham-Bold.woff2') format('woff2'),
		url('Gotham-Bold.woff') format('woff'),
		url('Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'Gotham Bold';
    src: url('Gotham-BoldItalic.eot');
	src: url('Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('Gotham-BoldItalic.woff2') format('woff2'),
		url('Gotham-BoldItalic.woff') format('woff'),
		url('Gotham-BoldItalic.svg#Gotham-BoldItalic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'Gotham Book';
    src: url('Gotham-Book.eot');
	src: url('Gotham-Book.eot?#iefix') format('embedded-opentype'),
		url('Gotham-Book.woff2') format('woff2'),
		url('Gotham-Book.woff') format('woff'),
		url('Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: 325;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'Gotham Book';
    src: url('Gotham-BookItalic.eot');
	src: url('Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
		url('Gotham-BookItalic.woff2') format('woff2'),
		url('Gotham-BookItalic.woff') format('woff'),
		url('Gotham-BookItalic.svg#Gotham-BookItalic') format('svg');
    font-weight: 325;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'Gotham Light';
    src: url('Gotham-Light.eot');
	src: url('Gotham-Light.eot?#iefix') format('embedded-opentype'),
		url('Gotham-Light.woff2') format('woff2'),
		url('Gotham-Light.woff') format('woff'),
		url('Gotham-Light.svg#Gotham-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'Gotham Light';
    src: url('Gotham-LightItalic.eot');
	src: url('Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('Gotham-LightItalic.woff2') format('woff2'),
		url('Gotham-LightItalic.woff') format('woff'),
		url('Gotham-LightItalic.svg#Gotham-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'Gotham Medium';
    src: url('Gotham-Medium.eot');
	src: url('Gotham-Medium.eot?#iefix') format('embedded-opentype'),
		url('Gotham-Medium.woff2') format('woff2'),
		url('Gotham-Medium.woff') format('woff'),
		url('Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: 350;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'Gotham Medium';
    src: url('Gotham-MediumItalic.eot');
	src: url('Gotham-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('Gotham-MediumItalic.woff2') format('woff2'),
		url('Gotham-MediumItalic.woff') format('woff'),
		url('Gotham-MediumItalic.svg#Gotham-MediumItalic') format('svg');
    font-weight: 350;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}
