@charset "utf-8";

/* CSS Document */



/*font-family:'Gotham Bold';

font-family:'Gotham Book';

font-family:'Gotham Light';

font-family:'Gotham Medium';*/



body{font-family:'Gotham Book'; font-size:14px; font-weight:400; color:#4f4f4f; background-color: #F5F2EB; line-height:20px;}

h1,h2,h3,h4,h5,h6{}

/* no-gutters Class Rules */

a{}

a:hover{text-decoration:none;}

.row.no-gutters {

   margin-right: 0;

   margin-left: 0;

}

.row.no-gutters > [class^="col-"],

.row.no-gutters > [class*=" col-"] {

   padding-right: 0;

   padding-left: 0;

}

.goToTop {

    position: fixed;

    top: 0;

    height: 40px;

    z-index: 9999;

}

/*----- new bg css -------*/

.mainbody-bg{background:#f5f2eb;}

.menu-border-top{border-top:1px solid #e3e4e4;}

.pad-top50{padding-top:50px;}

.bottom_block{ padding-bottom:50px;}

/*----- new bg css -------*/

header{ width:100%;

/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dbe8f1+0,5b7f95+100 */

background: #dbe8f1; /* Old browsers */

background: -moz-linear-gradient(top, #dbe8f1 0%, #5b7f95 100%); /* FF3.6-15 */

background: -webkit-linear-gradient(top, #dbe8f1 0%,#5b7f95 100%); /* Chrome10-25,Safari5.1-6 */

background: linear-gradient(to bottom, #dbe8f1 0%,#5b7f95 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dbe8f1', endColorstr='#5b7f95',GradientType=0 ); /* IE6-9 */

border-bottom:5px solid #633513;

}



.topblock{border-bottom:5px solid #633513;

/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#876945+0,bea77b+100 */

background: #876945; /* Old browsers */

background: -moz-linear-gradient(top, #876945 0%, #bea77b 100%); /* FF3.6-15 */

background: -webkit-linear-gradient(top, #876945 0%,#bea77b 100%); /* Chrome10-25,Safari5.1-6 */

background: linear-gradient(to bottom, #876945 0%,#bea77b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#876945', endColorstr='#bea77b',GradientType=0 ); /* IE6-9 */

}

.topblock p{font-family:'Gotham Light'; font-size:20px; font-style:italic; text-align:right; color:#fff; padding-top: 10px;}

.topblock p a{font-family:'Gotham Bold'; padding-left:10px; color:#fff; font-style:normal;}

.top-left-links{ list-style:none; padding:0; margin:0;}

.top-left-links li{float:left;}

.top-left-links li a{color:#fff; font-size:18px; padding:10px 5px; display:block;}

.top-left-links li a:hover{color:#adb5bd; text-decoration:none;}



.logo{float:left; padding:15px 0 0 0;}

.logo a{display:block;}

.logo a img{max-width:100%;}

.right-logo{float:right; padding-left: 20px;}

.phone-number{padding:0 0 0 68px; font-family:'Gotham Light'; font-size:18px; color:#3370b1; background:url(../../public/assets/img/call-icon.png) 0 0 no-repeat; float:left; margin-top:18px;}

.phone-number span{display:block; font-family:'Gotham Bold'; font-size:26px; line-height:36px;}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
   /* IE10+ specific styles go here */  
.logo a img{max-width:80% !important;
}
}





/* Change Autocomplete styles in Chrome*/

.has-float-label input:-webkit-autofill,

.has-float-label input:-webkit-autofill:hover, 

.has-float-label input:-webkit-autofill:focus

.has-float-label input:-webkit-autofill, 

textarea:-webkit-autofill,

textarea:-webkit-autofill:hover

textarea:-webkit-autofill:focus,

select:-webkit-autofill,

select:-webkit-autofill:hover,

select:-webkit-autofill:focus {

  border-bottom: 1px solid #ddd4d4;

  -webkit-text-fill-color: #303734;

  -webkit-box-shadow: 0 0 0px 1000px #fff inset;

  transition: background-color 5000s ease-in-out 0s;

  height: 30px;

    margin-top: 10px;

}





.banner-slider{width:100%; float:left; position:relative;}

.banner-slider img{max-width:100%;}

.banner-slider .carousel-caption{text-align:left; top:218px;}

.banner-slider .carousel-caption h2{font-size:55px; font-family:'Gotham Bold'; text-transform:uppercase;}

.banner-slider .carousel-caption p{font-size:40px; font-family:'Gotham Medium'; }

.banner-slider .carousel-caption a{background:#da7716; font-family:'Gotham Medium'; color:#fff; padding:0 20px; border-radius:5px; display:inline-block; margin-top:10px; font-size:16px; 

    line-height: 50px;

    height: 50px;

	-webkit-transition: all 0.5s ease-in-out;

	-moz-transition:    all 0.5s ease-in-out;

	-o-transition:      all 0.5s ease-in-out;

	-ms-transition:     all 0.5s ease-in-out;

	}

.banner-slider .carousel-caption a:hover{text-decoration:none; background:#6b5a46; color:#fff;}

.banner-slider .carousel-indicators{display:none;}

.owl-theme .owl-nav.disabled + .owl-dots, .owl-theme .owl-nav {
  position: relative;
  margin-top: -30px;
}

.welcome-content,.midsec2{width:100%; float:left;

/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dbe8f1+0,5b7f95+100 */

background: #dbe8f1; /* Old browsers */

background: -moz-linear-gradient(top, #dbe8f1 0%, #5b7f95 100%); /* FF3.6-15 */

background: -webkit-linear-gradient(top, #dbe8f1 0%,#5b7f95 100%); /* Chrome10-25,Safari5.1-6 */

background: linear-gradient(to bottom, #dbe8f1 0%,#5b7f95 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dbe8f1', endColorstr='#5b7f95',GradientType=0 ); /* IE6-9 */

}

.welcome-content h1{color:#4b6f88; font-family:'Gotham Bold';  font-size:36px; line-height:34px; font-weight:700; margin:0 0 45px 0;}

.welcome-content img{float:left;}

.welcome-content p{color:#525252; font-size:18px; line-height:34px; padding-bottom:30px;}

.welcome-content a{background:#e1002e; color:#fff; padding:0 20px; border-radius:25px; display:inline-block; margin-top:10px; font-size:16px; text-transform:uppercase;

    line-height: 40px;

    height: 40px;

	-webkit-transition: all 0.5s ease-in-out;

	-moz-transition:    all 0.5s ease-in-out;

	-o-transition:      all 0.5s ease-in-out;

	-ms-transition:     all 0.5s ease-in-out;

	}

.welcome-content a:hover{text-decoration:none; background:#930146; color:#fff;}

.slider{}

.slider h2{font-size:24px; font-family: 'PT Serif', serif; text-transform:uppercase; color:#2d1919;}

.content-block{width:100%; float:left;}





.midsec1{ width:100%; float: left; min-height:280px; text-align:center; border-bottom:3px solid #8a765d;

/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ccaf83+0,694e30+100 */

background: #ccaf83; /* Old browsers */

background: -moz-linear-gradient(top, #ccaf83 0%, #694e30 100%); /* FF3.6-15 */

background: -webkit-linear-gradient(top, #ccaf83 0%,#694e30 100%); /* Chrome10-25,Safari5.1-6 */

background: linear-gradient(to bottom, #ccaf83 0%,#694e30 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccaf83', endColorstr='#694e30',GradientType=0 ); /* IE6-9 */

}

.midsec1 .circle{width:178px; height:178px; border:2px solid #fff; border-radius:50%; margin:auto; margin-top:30px; font-size:60px; color:#fff; line-height:172px;}

.midsec1 h3{font-family:'Gotham Medium'; color:#fff;}



.midsec2{text-align:center; }

.midsec2 h3{color:#6b5a46; font-family:'Gotham Bold'; font-size:24px; line-height:30px;}

.midsec2 p{color:#4b6f88; font-size:18px; font-family:'Gotham Light';}

.midsec2 .block{width:350px; height:150px; overflow:hidden; }

.midsec2 .block:hover{}

.midsec2 a{background:#da7716; font-family:'Gotham Medium'; color:#fff; padding:0 20px; border-radius:5px; display:inline-block; margin-top:20px; font-size:14px; 

    line-height: 40px;

    height: 40px;

	-webkit-transition: all 0.5s ease-in-out;

	-moz-transition:    all 0.5s ease-in-out;

	-o-transition:      all 0.5s ease-in-out;

	-ms-transition:     all 0.5s ease-in-out;

	}

.midsec2 a:hover{text-decoration:none; background:#6b5a46; color:#fff;}



.testimonial{width:100%; float:left; background:#f7f7f7; margin:30px 0;}

#testimonial4{

  overflow: hidden;

  min-height: 430px;

  position: relative;

}

#testimonial4 .carousel-inner{

 /* width: 75%;

  margin: auto;*/

}

#testimonial4 .carousel-inner:hover{

  cursor: -moz-grab;

  cursor: -webkit-grab;

}

#testimonial4 .carousel-inner:active{

  cursor: -moz-grabbing;

  cursor: -webkit-grabbing;

}

#testimonial4 .carousel-inner .item{

  overflow: hidden;

}



.testimonial4_indicators .carousel-indicators{

  left: 0;

  margin: 0;

  width: 100%;

  font-size: 0;

  height: 20px;

  bottom: 15px;

  padding: 0 5px;

  cursor: e-resize;

  overflow-x: auto;

  overflow-y: hidden;

  position: absolute;

  text-align: center;

  white-space: nowrap;

}

.testimonial4_indicators .carousel-indicators li{

  padding: 0;

  width: 14px;

  height: 14px;

  border: none;

  text-indent: 0;

  margin: 2px 3px;

  cursor: pointer;

  display: inline-block;

  background: #4b6f88;

  -webkit-border-radius: 100%;

  border-radius: 100%;

}

.testimonial4_indicators .carousel-indicators .active{

  padding: 0;

  width: 14px;

  height: 14px;

  border: none;

  margin: 2px 3px;

  background-color: #da7716;

  -webkit-border-radius: 100%;

  border-radius: 100%;

}

.testimonial4_indicators .carousel-indicators::-webkit-scrollbar{

  height: 3px;

}

.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb{

  background: #eeeeee;

  -webkit-border-radius: 0;

  border-radius: 0;

}



.testimonial4_control_button .carousel-control{

  top: 175px;

  opacity: 1;

  width: 40px;

  bottom: auto;

  height: 40px;

  font-size: 10px;

  cursor: pointer;

  font-weight: 700;

  overflow: hidden;

  line-height: 38px;

  text-shadow: none;

  text-align: center;

  position: absolute;

  background: transparent;

  border: 2px solid #ffffff;

  text-transform: uppercase;

  -webkit-border-radius: 100%;

  border-radius: 100%;

  -webkit-box-shadow: none;

  box-shadow: none;

  -webkit-transition: all 0.6s cubic-bezier(0.3,1,0,1);

  transition: all 0.6s cubic-bezier(0.3,1,0,1);

}

.testimonial4_control_button .carousel-control.left{

  left: 7%;

  right: auto;

}

.testimonial4_control_button .carousel-control.right{

  right: 7%;

  left: auto;

}

.testimonial4_control_button .carousel-control.left,.testimonial4_control_button .carousel-control.right{display:none;}

.testimonial4_control_button .carousel-control.left:hover,

.testimonial4_control_button .carousel-control.right:hover{

  color: #000;

  background: #fff;

  border: 2px solid #fff;

}



.testimonial4_header{

  top: 0;

  left: 0;

  bottom: 0;

  width: 550px;

  display: block;

  margin: 30px auto;

  text-align: center;

  position: relative;

}

.testimonial4_header h4{

  font-family:'Gotham Bold';

  color: #4b6f88;

  font-size: 36px;

  position: relative;

}



.testimonial4_slide{

  top: 0;

  left: 30px;

  right: 0;

  bottom: 0;

  position: relative;



}

.testimonial4_slide img {

  top: 0;

  left: 0;

  right: 0;

  /* width: 115px;

  height: 115px; */

  margin: auto;

  display: block;

  position: relative;

  border-radius:50%;

}



.testimonial4_slide p {

  color: #686868;

  font-size: 16px;

  line-height:24px;

font-family:'Gotham Book';

margin:40px 0;

}



.testimonial4_slide h4 {

  color: #5d5e5e;

  font-size: 18px;

  font-family:'Gotham Bold';

}





.GScroll{

    position:relative;

    overflow:hidden;

	margin-top:15px;

}

.GScroll > .GScroll-scrollable{

    position:absolute;

    width:100%;

    top:0px;

    left:0px;

	padding:0 15px 15px 15px;

	

}

.GScroll > .GScroll-bar{

    position:absolute;

    width:10px;

    min-height:10px;

    top:0px;

    right:0px;

    display:none;

    background-color: rgba(0,0,0,0.6);

    cursor: move;

    z-index:2;

	border-radius:10px;

}



@media only screen and (max-width: 480px){

  .testimonial4_control_button .carousel-control{

    display: none;

  }

  .testimonial4_header{

    width: 95%;

  }

  .testimonial4_header h4{

    font-size: 20px;

  }

  .testimonial4_slide{

    width: 98%;

    padding: 5px;

  }

}



@media (min-width: 481px) and (max-width: 767px){

  .testimonial4_control_button .carousel-control.left{

    left: 2%;

  }

  .testimonial4_control_button .carousel-control.right{

    right: 2%;

  }

  .testimonial4_header{

    width: 95%;

  }

  .testimonial4_slide{

    width: 98%;

    padding: 5px;

  }

}



@media (min-width: 768px) and (max-width: 991px){

  .testimonial4_control_button .carousel-control.left{

    left: 5%;

  }

  .testimonial4_control_button .carousel-control.right{

    right: 5%;

  }

}



@-webkit-keyframes psBoxShadowEffect_2{

  0% {

    opacity: 0.3;

  }

  40% {

    opacity: 0.5;

	-webkit-box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #ffffff, 0 0 0 10px rgba(255,255,255,0.5);

  }

  100% {

    -webkit-box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 5px 5px #ffffff, 0 0 0 5px rgba(255,255,255,0.5);

    -webkit-transform: scale(1.5);

    opacity: 0;

  }

}

@keyframes psBoxShadowEffect_2{

  0% {

    opacity: 0.3;

  }

  40% {

    opacity: 0.5;

	box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #ffffff, 0 0 0 10px rgba(255,255,255,0.5);

  }

  100% {

    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 5px 5px #ffffff, 0 0 0 5px rgba(255,255,255,0.5);

    transform: scale(1.5);

    opacity: 0;

  }

}



.blog{}

.blog ul{padding:0; margin:0; list-style:none;}

.blog ul li{float:left; margin-bottom:15px; }

.blog ul li a{display:block; color:#525252;
background:none; padding:0; margin:0; line-height:inherit; text-align:left; height:auto; font-family:'Gotham Book';}

.blog ul li a:hover{background:none;}

.blog ul li p a { font-size:13px; color:#141413;}

.blog ul li a img{float:left; margin:0 15px 15px 0;}

.blog{ position:relative; height:400px; overflow:hidden;}



.play-pouse{position:absolute; right:15px; top:10px;}

.play-pouse a{color:#fff; font-size:12px; background-color:#930146; padding:3px 7px; display:inline-block;}

.demof{

    /*border: 1px solid #ccc;

    margin: 20px 0;*/

}

.demof ul{width:100%;

    padding: 0;

    list-style: none;

}

.demof li{

    padding:10px;

    border-bottom: 1px dashed #fff;

}

.demof li.odd{

    background: #fafafa;

}

.demof li:after {

    content: '';

    display: block;

    clear: both;

}

.demof img{

    float: left;

    width: 150px;

    margin: 5px 15px 0 0;

}

.demof a{color: #5a4e4e; font-size: 16px;

    text-decoration: none;

    display: block;

}

.demof li span{display:block;}

.demof p {

    margin:0;

    font-size: 14px;

}



.demo5{

    border: 2px solid #ccc;

    margin-top: 10px;

}

.demo5 ul{

    padding: 0;

}

.demo5 ul li{

    padding: 10px 10px 10px 10px;

    border-bottom: 1px solid #ccc;

    list-type: none;

    margin: 0;

}

.video{}

.video iframe{border:1px solid #e3e4e4; padding:5px; background:#f8f8f8;}



.feedback{}

.feedback .form-horizontal{border:1px solid #e3e4e4; background:#f8f8f8; padding:20px;}

.feedback .form-horizontal .form-group {margin:0 0 15px 0;}

.feedback .form-horizontal .form-group .form-control{border-radius:0;}

.video h2,.blog h2,.feedback h2{color:#4b6f88; font-size:30px; font-family:'Gotham Bold'; margin-top:0;}



.blog .thumb{}

.blog .thumb h3{margin:0; color:#930146; text-decoration:underline; font-family: 'PT Sans', sans-serif}

.termsofuse a{ background:#e1002e; color:#fff; padding:0 20px; border-radius:25px; float:right; margin-top:10px; font-size:14px; text-transform:uppercase;

    line-height: 40px;

    height: 40px;

	-webkit-transition: all 0.5s ease-in-out;

	-moz-transition:    all 0.5s ease-in-out;

	-o-transition:      all 0.5s ease-in-out;

	-ms-transition:     all 0.5s ease-in-out;

	}

.termsofuse a:hover{text-decoration:none; background:#930146; color:#fff;}



.feedback .btn-submit{background:#da7716; font-family:'Gotham Medium'; color:#fff; padding:0 20px; border-radius:5px; display:inline-block; margin-right:-15px; font-size:14px; 

    line-height: 40px;

    height: 40px;

	-webkit-transition: all 0.5s ease-in-out;

	-moz-transition:    all 0.5s ease-in-out;

	-o-transition:      all 0.5s ease-in-out;

	-ms-transition:     all 0.5s ease-in-out;

	}

.feedback .btn-submit:hover{text-decoration:none; background:#6b5a46; color:#fff;}



.newsletter-block{ width:100%; height:100px; float:left; background-color:#d6d6d6; margin:0 0 0 0; padding:10px 0;}

.newsletter-block h2{font-size:24px; font-family: 'PT Sans', sans-serif; font-weight:700; margin:0 0 10px 0; color:#91013f; text-transform:uppercase;}

.newsletter-block form {}



.newsletter-block input[type=text] {

height: 40px;

width: 70%;

vertical-align: top;

border-radius: 25px;

border:1px solid #b2b2b2;

background: #fff;

line-height: 20px;

padding: 5px 10px 5px 20px;

font-size:14px;

color: #a5a5a5;

}



.newsletter-block .btn {

padding:7px 18px;

font-size: 16px;

background-color:#e1002e;

color:#fff;

border-radius:25px;

margin-left:-50px;

}



footer{width:100%; float:left; background:#633513; padding-top: 20px;}

footer h3{color:#8bb7d5; font-family:'Gotham Medium'; font-size:18px;}

footer p{color:#b9b9b9; font-size:12px;}

footer ul.foot-links{list-style:none; }

footer ul.foot-links li{float:left; width:50%; }

footer ul.foot-links li a{color:#b9b9b9 !important; font-size:12px; cursor: pointer;}

footer .newsletter{}

footer .newsletter .form-control{border-radius:0; background:#c2c2c2;}

footer .newsletter .btn-submit{margin-top:20px; background: #da7716;

    font-family: 'Gotham Medium';

    color: #fff;

    padding: 5px 12px;

    border-radius: 5px;

    display: inline-block;

	-webkit-transition: all 0.5s ease-in-out;

	-moz-transition:    all 0.5s ease-in-out;

	-o-transition:      all 0.5s ease-in-out;

	-ms-transition:     all 0.5s ease-in-out;

	}

footer p span{padding-right:10px;}

.socialicons{ list-style:none; padding:0; margin:0;}

.socialicons li{ display:inline-block;padding-right: 6px;}

.socialicons li a{color:#adb5bd;}

.footer-social{ text-align:center;}





.copyright{padding:10px 0; font-weight:400; text-align:center; background:#48250c; color:#a38760;}

.copyright p{color:#39031a; font-size:16px;}

.invalid-feedback {
  text-align: left;
}



/* Inner pages css ================*/



.innerpage {min-height: 500px; width: 100%;  float: left; padding-bottom:30px;}

.innerpage img{float:left; margin:0 15px 15px 0;}

.innerpage-banner{ width:100%; float:left;}

.innerpage-banner img{max-width:100%; width:100%;}

.innerpage h1{color: #4b6f88; font-family: 'Gotham Bold'; font-size: 36px; line-height: 34px;  font-weight: 700; margin: 30px 0 15px 0;}



.innerpage .breadcrumb {

	background: none;

	border-bottom: 1px solid #d1d1d1;

	border-radius: 0;

}

.innerpage h3 {

	color: #2d1919;

	font-size: 16px;

	font-weight: 600;

	margin-top:0;

}

.innerpage p {

	font-size: 14px;

	line-height: 24px;

	color:#39393b;

}

.innerpage ul{padding:0; margin:0 0 0 15x; list-style:none;}

.innerpage ul li{padding:5px 0 5px 30px; font-size:16px;}



.innerpage .testimonial {

	padding: 15px;

	border-bottom: 1px solid #f9f9f9;

	margin-top:0;

}



.innerpage .testimonial .testi-block{width:160px; height:160px; }

.innerpage .testimonial .testi-block img {

	border-radius: 50%;

	display: block;

	max-width:100%;

	border:2px solid #c9c9c9;

}

.innerpage .testimonial h3 {

	color: #da7716;

    font-size: 16px;

    font-family: 'Gotham Bold';

	text-transform:uppercase;

}

.innerpage .testimonial h4{text-align:right; color:#4b6f88; font-family: 'Gotham Bold'; font-size:13px;}

.content {

	margin-top:0;

}

.content h2{margin-top:0;}

.content p {font-size: 14px; line-height: 24px;}

.content ul {

	list-style: none;

	padding: 0;

}

.content ul li {

}

.content .table thead {

	background-color: #144879;

	color: #eff4f8;

	font-weight: 600;

	font-size: 16px;

	text-transform: uppercase;

}

.innerpage .blog{

	border-bottom: 1px solid #d2d2d2;

	margin-bottom:20px; height:auto;}

.innerpage .blog .blog-thumb{width:255px; }

.innerpage .blog .blog-thumb img{max-width:100%; border:2px solid #e4e4e4;}

.innerpage .blog a,.innerpage .blog-details a{background:#da7716; color:#fff; padding:0 12px; border-radius:5px; display:inline-block; margin-bottom:20px; font-size:12px; 

    line-height: 26px;

    height: 26px;

	-webkit-transition: all 0.5s ease-in-out;

	-moz-transition:    all 0.5s ease-in-out;

	-o-transition:      all 0.5s ease-in-out;

	-ms-transition:     all 0.5s ease-in-out;

	}

.innerpage .blog a:hover,.innerpage .blog-details a:hover{text-decoration:none; background:#6b5a46; color:#fff;}

.innerpage .blog-details{}

.innerpage .blog-details .blog-img{}

.innerpage .blog-details .blog-img img{float:none;}





.contact{ border: 1px solid #e3e4e4; background: #f8f8f8; font-family: 'Gotham Medium'; padding: 20px; margin-bottom: 20px;}

.contact h4{color:#6a5a46; font-size:15px; font-family: 'Gotham Bold'; text-decoration:underline; text-transform:uppercase; }

.contact p span{margin-right:20px;}

.contact .map{}

.contact .map iframe{width:100%;}





@media screen and (max-width: 1024px){

.top-right h2{font-size:20px;}

.top-right h2 span{font-size:24px;}

.top-right ul.links li{font-size:14px; padding:8px 2px;}

.navbar{min-height:40px;}

.navbar-nav > li > a{font-size:16px; padding:14px 18px;}

.testimonial{height:auto;}

.top-flag{margin-left:0; margin-right:0;}

.top-flag img{max-width:100%; height:auto;}	

.midlabel .midbox img{max-width:100%; height:auto;}

.footer img {max-width: 100%; height: auto;}

}



@media screen and (max-width: 768px){

.top-right ul.links{margin:0;}

.top-right ul.links li{padding: 4px 2px;}

.top-social{margin-bottom:0;}

.top-right h2{margin-top:0; font-size:16px;}

.top-right h2 span{font-size:16px;}

.navbar-nav > li > a {font-size: 14px;  padding: 10px 10px;}

.latest-box ul li div.event-block h3, .latest-box ul li div.event-block h3 a{font-size:20px;}

#tcb-testimonial-carousel .text-brand{font-size:16px;}

.footer p{padding-left:0;}

.footer ul li{padding:0 0 3px 0px;}

.copyright{margin-top:0;}


}



@media screen and (max-width: 640px){

.logo{height:auto;}

.logo img{max-width:70%;}

.navbar-toggle{background:#930146;}

.navbar-toggle .icon-bar{background:#fff;}

.banner-slider{height:auto;}

.welcome-content .container{margin-top:0;}	

.banner-slider .carousel-caption p{font-size:12px; line-height:13px;}

.carousel-caption{padding:0;}

.banner-slider .carousel-caption{top:0;}

.banner-slider .carousel-caption h2{margin-bottom:0; font-size:18px;}

.testimonial4_slide{width:100%;}

#testimonial4 .carousel-inner{width:100%;}

.testimonial4_header{margin-bottom:0;}

.testimonial4_slide p{margin-top:0;}

.testimonial4_slide img{width:180px; height:180px;}

.testimonial4_slide p{margin-left:0;}

.termsofuse{height:auto;}



.innerpage .testimonial p,.innerpage .testimonial h3{padding-left:20px;}

.innerpage .testi-block{border-right:0;}

.innerpage .registerbg{height:auto;}

.innerpage .registerbg .registerbox{width:100%; padding:15px;}



.review{padding-bottom:15px;}


}

.has-sub ul.show {display: block !important;}
.rc-anchor-normal,.rc-anchor-normal .rc-anchor-content,.rc-anchor-normal .rc-anchor-checkbox-label{width:auto !important;}